import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"; // 引入 i18next
import "./VideoIntroduction.css";

function VideoIntroduction() {
  const { t, i18n } = useTranslation(); // 获取翻译函数和当前语言
  const [isMobile, setIsMobile] = useState(false);
  const [videos, setVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // 检测是否为移动端
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 从后端获取视频数据
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch(`https://qyakty.com/api/videos?lang=${i18n.language}`); // 动态请求语言
        if (!response.ok) throw new Error("Failed to fetch videos");
        const data = await response.json();
        setVideos(data);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, [i18n.language]); // 监听语言变化，重新获取视频数据

  const handleNext = () => {
    setCurrentVideoIndex((prev) => (prev + 1) % videos.length);
  };

  const handlePrev = () => {
    setCurrentVideoIndex((prev) => (prev - 1 + videos.length) % videos.length);
  };

  if (videos.length === 0) return <p>{t("loading")}</p>; // 动态翻译加载提示

  return (
    <div className="video-introduction">
      <h2>{t("video.title")}</h2>
      <p>{t("video.description")}</p>

      {isMobile ? (
        <div className="video-carousel">
          <iframe
            src={videos[currentVideoIndex]?.src}
            title={videos[currentVideoIndex]?.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>{videos[currentVideoIndex]?.title}</p>
          <div className="carousel-controls">
            <button onClick={handlePrev}>{t("video.prev")}</button>
            <button onClick={handleNext}>{t("video.next")}</button>
          </div>
        </div>
      ) : (
        <div className="video-grid">
          {videos.map((video) => (
            <div className="video-item" key={video.id}>
              <iframe
                src={video.src}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p>{video.title}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default VideoIntroduction;
